import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { useTranslation } from "react-i18next";

const Styles = styled.aside`
  && {
    background: var(--t-white);
    margin: 0 auto;
    color: var(--t-black);
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 1.4em 0;
    max-width: 100%;
    flex-direction: column;
    @media(min-width: 60em) {
      flex-direction: row;
    }
    p {
      margin: 0;
      font-size: 1em;
      @media(min-width: 35em) {
        font-size: 1.2em;
      }
      @media(min-width: 60em) {
        &:not(:last-child) {
          border: 0;
          &:after {
            content: "|";
            padding-left: 0.5em;
            padding-right: 0.5em;
          }
        }
      }
    }
    strong {
      color: var(--t-magenta);
      font-weight: 600;
    }
  }
`

export default function TheEnd() {
  const { t, i18n } = useTranslation();

  const changeLanguageHandler = (lng) => {
    i18n.changeLanguage(lng);
  }

  return (
    <Styles>
      {/* <p>
        { i18n.language === 'fi' ? // TODO is this enough?
          (
            <button className="a" onClick={() => changeLanguageHandler("en")}>
              In English
            </button>
          ) : (
            <button className="a" onClick={() => changeLanguageHandler("fi")}>
              Suomeksi
            </button>
          )
        }
      </p> */}
      <p><Link className="link" to="/meta">{t('common.meta')}</Link></p>
      <p><a href="https://www.linkedin.com/company/taitopilvi/">Linkedin</a></p>
      <p><a href="https://www.youtube.com/channel/UCLFvlOfgnDyFzeMIxUWaEag">YouTube</a></p>
      <p><a href="https://www.instagram.com/taitopilvi/">Instagram</a></p>
      <p><a href="https://omistajaksi.taitopilvi.fi">{t('footer.careers')}</a></p>
    </Styles>
  )
}