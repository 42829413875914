import { Link } from 'gatsby'
import * as React from 'react'
import styled from 'styled-components'
import Layout from '@components/layout'
import TheEnd from '@components/theend'
import { useTranslation } from 'react-i18next'
import Box from '@common/box'

const StyledUl = styled.ul`
  && {
    li {
      list-style-type: disc;
      line-height: 1.666;
    }
  }
`

export default function Kiitos() {
  const {t} = useTranslation()

  return (
    <Layout hasTitle={false}>
      <Box>
        <header>
          <h1>{t('thanks.title')}</h1>
          <p>{t('thanks.description')}</p>
        </header>
        <section>
          <h3>{t('thanks.content.t1')}</h3>
          <p>{t('thanks.content.p1_1')} <Link to="/#asiakascaset">{t('thanks.content.p1_link')}</Link>{t('thanks.content.p1_2')}</p>
          <h3>{t('thanks.content.t2')}</h3>
          <StyledUl>
            <li>{t('thanks.content.li1')} <a href="https://omistajaksi.taitopilvi.fi">{t('thanks.content.li1_link')}</a></li>
            <li>{t('thanks.content.li2')} <a href="https://www.linkedin.com/company/taitopilvi/">{t('thanks.content.li2_link')}</a></li>
            <li>{t('thanks.content.li3')} <a href="https://www.instagram.com/taitopilvi/">{t('thanks.content.li3_link')}</a></li>
            <li>{t('thanks.content.li4')} <a href="https://www.youtube.com/channel/UCLFvlOfgnDyFzeMIxUWaEag">{t('thanks.content.li4_link')}</a></li>
          </StyledUl>
        </section>
      </Box>
      <TheEnd />
    </Layout>
  )
}
